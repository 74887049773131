<template>
  <div>
    <div class="row">
      <el-button
        v-show="
          information == 'sharedClients' &&
            permissions == false &&
            revokedPermissions == false
        "
        @click="
          () => {
            exportDataExcel = true;
            exportExcel();
          }
        "
        >Export CSV</el-button
      >
      <div
        class="col-2 ml-2 p-0"
        v-if="permissions == false && revokedPermissions == false"
      >
        <el-select
          v-model="information"
          placeholder="Information"
          class="w-100"
        >
          <el-option value="sharedClients" label="Shared Clients"
            >Shared Clients</el-option
          >
          <el-option
            value="clientsWithUploadedFiles"
            label="Clients with uploaded files"
            >Clients with uploaded files</el-option
          >
        </el-select>
      </div>
      <div
        v-if="permissions == false && revokedPermissions == false"
        class="col-3 ml-2 p-0"
      >
        <multiselect
          v-model="oficeFilter"
          @input="filterData"
          :options="offices"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select Offices"
          label="office_name"
          track-by="id"
          :select-label="''"
          :deselect-label="''"
          @open="openBackgroundOpacity"
          @close="closeBackgroundOpacity"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{
                values
                  .map((item) => {
                    return item.office_name;
                  })
                  .join(", ")
              }}</span
            ></template
          >
        </multiselect>
      </div>
      <div
        class="col-1 ml-2 p-0"
        v-if="permissions == false && revokedPermissions == false"
      >
        <el-select
          v-model="typeClient"
          @input="filterData"
          placeholder="Type Client"
          class="w-100"
        >
          <el-option value="both" label="Both">Both</el-option>
          <el-option value="Commercial" label="Commercial"
            >Commercial</el-option
          >
          <el-option value="Personal" label="Personal">Personal</el-option>
        </el-select>
      </div>
      <div
        class="col-1 ml-2 p-0"
        v-if="permissions == false && revokedPermissions == false"
      >
        <el-select
          v-model="activeApp"
          @input="filterData"
          placeholder="Active App"
          class="w-100"
        >
          <el-option value="both" label="Both">Both</el-option>
          <el-option value="1" label="Yes">Yes</el-option>
          <el-option value="0" label="No">No</el-option>
        </el-select>
      </div>
      <div
        class="col-2 ml-2 p-0"
        v-if="permissions == false && revokedPermissions == false"
      >
        <el-select
          v-model="usersWithPermissions"
          @input="searchSharedClientsFilter"
          placeholder="Permissions Status"
          class="w-100"
        >
          <el-option value="active" label="Active Permissions"
            >Active Permissions</el-option
          >
          <el-option value="revoked" label="Revoked Permissions"
            >Revoked Permissions</el-option
          >
        </el-select>
      </div>
      <div class="col-3 ml-2 p-0" v-else>
        <el-button
          @click="
            () => {
              permissions = false;
              revokedPermissions = false;
            }
          "
          >Go Back</el-button
        >
        <el-button
          v-if="permissions"
          @click="
            () => {
              permissions = false;
              revokedPermissions = true;
            }
          "
          >Revoked Permissions</el-button
        >
        <el-button
          v-if="revokedPermissions"
          @click="
            () => {
              permissions = true;
              revokedPermissions = false;
            }
          "
          >Active Permissions</el-button
        >
      </div>
    </div>
    <!-- Active Shared Shared Clients -->
    <el-table
      :default-sort="{ prop: 'name', order: 'ascending' }"
      id="exportSharedClients"
      v-show="
        information == 'sharedClients' &&
          permissions == false &&
          revokedPermissions == false
      "
      :data="
        this.tableDataSharedClients.filter((dataSharedClients) =>
          !searchSharedClients || dataSharedClients.name
            ? dataSharedClients.name
                .toLowerCase()
                .includes(searchSharedClients.toLowerCase())
            : true || dataSharedClients.securitySocial
            ? dataSharedClients.securitySocial
                .toLowerCase()
                .includes(searchSharedClients.toLowerCase())
            : true
        )
      "
      class="w-100"
    >
      <el-table-column sortable prop="name" label="Name"></el-table-column>
      <el-table-column width="150" sortable prop="active" label="Active App">
        <template slot-scope="scope">
          {{ scope.row.active === 1 ? "Yes" : "No" }}
        </template>
      </el-table-column>
      <el-table-column
        width="150"
        sortable
        prop="created_at"
        label="Share Date"
      >
        <template slot-scope="scope">{{
          scope.row.created_at | moment("MM-DD-YYYY")
        }}</template>
      </el-table-column>
      <el-table-column
        width="150"
        sortable
        prop="activated_at"
        label="Activation Date"
      >
        <template slot-scope="scope">{{
          scope.row.activated_at | moment("MM-DD-YYYY")
        }}</template>
      </el-table-column>
      <el-table-column
        sortable
        prop="discriminator"
        label="Discriminator"
      ></el-table-column>
      <el-table-column
        prop="securitySocial"
        label="Security Social"
      ></el-table-column>
      <el-table-column
        v-if="exportDataExcel === true"
        width="120"
        sortable
        prop="created_at"
        label="Created At"
      >
        <template slot-scope="scope">{{
          scope.row.created_at | moment("MM-DD-YYYY")
        }}</template>
      </el-table-column>
      <el-table-column
        v-if="exportDataExcel === true"
        width="120"
        sortable
        prop="activated_at"
        label="Activated At"
      >
        <template slot-scope="scope">{{
          scope.row.activated_at | moment("MM-DD-YYYY")
        }}</template>
      </el-table-column>
      <el-table-column
        label="Operations"
        width="180"
        v-if="exportDataExcel === false"
      >
        <template slot="header" slot-scope="scope">
          <el-input
            v-model="searchSharedClients"
            placeholder="Search..."
            class="p-0"
            :key="scope.row"
          />
        </template>
        <template slot-scope="scope">
          <el-button-group>
            <el-button @click="showPermissions(scope.row)"
              >Show Permissions</el-button
            >
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <!-- Permissions Shared Client -->

    <el-table
      v-show="permissions == true"
      :data="tableDataPermissions"
      class="w-100"
    >
      <el-table-column prop="path" label="Path"></el-table-column>
      <el-table-column width="300" sortable prop="createdAt" label="Due Date">
        <template slot-scope="scope">
          {{ scope.row.createdAt | moment("MM-DD-YYYY") }}
        </template>
      </el-table-column>
      <el-table-column sortable prop="type" label="Type"></el-table-column>
      <el-table-column label="Operations" width="220">
        <template slot-scope="scope">
          <el-button-group>
            <el-button @click="removePermission(scope.row, scope.$index)"
              >Remove Permission</el-button
            >
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <el-table
      v-show="revokedPermissions == true"
      :data="tableDataRevokedPermissions"
      class="w-100"
    >
      <el-table-column prop="path" label="Path"></el-table-column>
      <el-table-column width="300" sortable prop="createdAt" label="Due Date">
        <template slot-scope="scope">
          {{ scope.row.createdAt | moment("MM-DD-YYYY") }}
        </template>
      </el-table-column>
      <el-table-column sortable prop="type" label="Type"></el-table-column>
    </el-table>

    <el-table
      v-show="information == 'clientsWithUploadedFiles'"
      :data="tableDataUsersWithNotifications"
      class="w-100"
    >
      <el-table-column prop="name" label="Name"></el-table-column>
      <el-table-column
        prop="discriminator"
        label="Discriminator"
      ></el-table-column>
      <el-table-column
        prop="securitySocial"
        label="Security Social"
      ></el-table-column>
      <el-table-column label="Operations" width="200">
        <template slot-scope="scope">
          <el-button-group>
            <el-button
              icon="el-icon-search"
              @click="viewNotifications(scope.row)"
            >
              View Notifications</el-button
            >
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <el-table
      v-show="information == 'NotificationsOfUser'"
      :data="tableDataNotificationsOfUser"
      class="w-100"
    >
      <el-table-column
        sortable
        prop="uploadUserName"
        label="User Name"
      ></el-table-column>
      <el-table-column
        sortable
        prop="uploadDirectory"
        label="Location Folder"
      ></el-table-column>
      <el-table-column
        sortable
        prop="fileName"
        label="File Name"
      ></el-table-column>
      <el-table-column
        sortable
        prop="targetFolder"
        label="Target Folder"
      ></el-table-column>
      <el-table-column
        width="150"
        sortable
        prop="uploadDate"
        label="Upload Date"
      >
        <template slot-scope="scope">{{
          scope.row.uploadDate | moment("MM-DD-YYYY")
        }}</template>
      </el-table-column>
      <el-table-column label="Operations" width="100">
        <template slot-scope="scope">
          <el-button-group>
            <el-button
              icon="el-icon-success"
              @click="completeNotification(scope.row, scope.$index)"
            ></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import documentManager from "@/services/api/documentManager";
import Multiselect from "vue-multiselect";
import office from "@/services/api/office";
import FileSaver from "file-saver";
import XLSX from "xlsx";

export default {
  name: "inbound-panel",
  components: {
    Multiselect,
  },
  data() {
    return {
      searchSharedClients: "",
      dataSharedClients: [],
      exportDataExcel: false,
      tableDataSharedClients: [],
      tableDataPermissions: [],
      tableDataRevokedPermissions: [],
      tableDataUsersWithNotifications: [],
      tableDataNotificationsOfUser: [],
      usersWithPermissions: "active",
      search: "",
      offices: [],
      typeClient: "both",
      activeApp: 'both',
      oficeFilter: [],
      information: "sharedClients",
      permissions: false,
      revokedPermissions: false,
      discriminator: "shareholder",
    };
  },
  mounted() {
    office.get().then((response) => {
      this.offices = response;
    });
    documentManager.getSharedClients(false).then((response) => {
      this.tableDataSharedClients = response.result.info;
      this.dataSharedClients = response.result.info;
    });
    documentManager
      .getUsersWithNotifications({ discriminator: this.discriminator })
      .then((response) => {
        this.tableDataUsersWithNotifications = response.result.info;
      });
  },
  methods: {
    exportExcel() {
      setTimeout(() => {
        /* generate workbook object from table */
        var xlsxParam = { raw: true }; // parse only content derived, without performing format conversion
        var wb = XLSX.utils.table_to_book(
          document.querySelector("#exportSharedClients"),
          xlsxParam
        );

        /* get binary string as output */
        var wbout = XLSX.write(wb, {
          bookType: "csv",
          bookSST: true,
          type: "array",
        });
        try {
          FileSaver.saveAs(
            new Blob([wbout], { type: "application / octet-stream" }),
            "Control Panel Document Manager.csv"
          );
          this.exportDataExcel = false;
        } catch (e) {
          if (typeof console !== "undefined") {
            console.log(e, wbout);
          }
        }
        return wbout;
      }, 500);
    },
    filterData() {
      let idOffices = this.oficeFilter.map((office) => {
        return office.id;
      });

      this.tableDataSharedClients = Object.assign([], this.dataSharedClients);

      if (idOffices.length > 0) {
        this.tableDataSharedClients = this.tableDataSharedClients.filter(
          (item) => idOffices.indexOf(item.office) != -1
        );
      }

      if (this.typeClient !== "both") {
        this.tableDataSharedClients = this.tableDataSharedClients.filter(
          (item) => item.discriminator === this.typeClient
        );
      }

      if (this.activeApp !== "both") {
        this.tableDataSharedClients = this.tableDataSharedClients.filter(
          (item) => item.active == this.activeApp
        );
      }
    },
    searchSharedClientsFilter() {
      documentManager
        .getSharedClients(this.usersWithPermissions === "revoked")
        .then((response) => {
          this.tableDataSharedClients = response.result.info;
          this.dataSharedClients = response.result.info;
        });
    },
    showPermissions(row) {
      this.permissions = true;
      documentManager.permissionsSharedClient(row).then((response) => {
        this.tableDataPermissions = response.result.permissions;
      });
      documentManager.permissionsRevokedSharedClient(row).then((response) => {
        this.tableDataRevokedPermissions = response.result.permissions;
      });
    },
    removePermission(row, index) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#67C23A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, deactivate it!",
      }).then((result) => {
        if (result.value) {
          documentManager
            .deletePermission(row)
            .then((response) => {
              this.tableDataPermissions.splice(index, 1);
              documentManager
                .permissionsRevokedSharedClient(row)
                .then((response) => {
                  this.tableDataRevokedPermissions =
                    response.result.permissions;
                });
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error",
              });
            });
        }
      });
    },
    viewNotifications(row) {
      this.information = "NotificationsOfUser";
      documentManager
        .uploadNotificationsOfUser({ id: row.id })
        .then((response) => {
          this.tableDataNotificationsOfUser = response.result.notifications;
        })
        .catch(() => {
          this.$message({
            message: "Opps... Something wrong",
            type: "error",
            customClass: "message-error",
          });
        });
    },
    completeNotification(row, index) {
      this.$swal({
        title: "Are you sure?",
        text: "The Notification Will Be Disapear!",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          documentManager
            .disableUploadNotification({ id: row.id })
            .then((response) => {
              this.tableDataNotificationsOfUser.splice(index, 1);
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error",
              });
            });
        }
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
